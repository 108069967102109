import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Creating A Sense Of Community At Irvine`}</h1>
    <p>{`We've all had friends we lost touch with, especially during the COVID-19 pandemic. Though, this year, we didn't make as many friends as we normally do in a given year. I found some of my best friends through being lab partners with them or just passing notes to them in AP Euro. That probably didn't happen for many freshman and sophomores, and there wasn't a way for people to connect virtually too... considering a large part of Irvine High does the 100% online option, Irvine Virtual Academy.`}</p>
    <p>{`What if we created a live, digital community? There are communities like `}<a parentName="p" {...{
        "href": "https://hi.fiveable.me/community/"
      }}>{`Fiveable`}</a>{` or `}<a parentName="p" {...{
        "href": "https://hackclub.com/slack/"
      }}>{`ObjectiveCode`}</a>{` that engage in over 10,000 messages daily, in over 150 channels. This includes #music, #photography, #code, #dogs, and so much more. People who like a certain topic can join that channel to meet new people that share that interest. They can plan meet-ups, exchange contact information, and get to know each other better.`}</p>
    <p>{`We could also connect students in each class together using that option. In my fourth grade class at Deerfield Elementary (shoutout to Mrs. Larson), we used an app called `}<a parentName="p" {...{
        "href": "http://edmodo.com"
      }}>{`Edmodo`}</a>{` which let our whole class chat with each other after school. It was so fun and everyone used to post how their day was going, cool achievements they got, and more! I think it would be fun to implement class group chats for everyone to meet everyone, on their own time and terms. This makes it more inclusive as people who don't have social media (because their parents won't let them, etc) won't be left out of the conversation.`}</p>
    <p>{`What if we got every student at Irvine to join? We'd be able to make it so you sign in with your IUSD Google account, it'll take you to all your course group chats, you can join random chats with friends, every club would have a chat, and more!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      